/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// debulked onresize handler
// https://github.com/louisremi/jquery-smartresize

(function($) {
  var on_resize= function (c,t){onresize=function(){clearTimeout(t);t=setTimeout(c,100);};return c;};

  var initBanner = function () {
    $banner = $('.button-newsletter');
    if ($banner.length) {
      var display = Cookies.get('displaybanner');
      if (display !== 'hidden') {
        setTimeout(function() {
          $banner.addClass('attention');
          $banner.find('.close').on('click', function(event){
            event.preventDefault();
            $banner.removeClass('attention');
            Cookies.set('displaybanner', 'hidden', { expires: 7 });
          });
        }, 5000);


      }
    }
  };

  var initTrailerPopup = function () {
    $trailer = $('.trailer-overlay');
    if ($trailer.length) {
      var displayDays = $trailer.data('days');
      var display = Cookies.get('displaytrailer');
      if (display !== 'hidden' || displayDays === 0) {
        $trailer.addClass('show');
        $trailerbutton = $trailer.find('.attachment-full');
        $iframe = $trailer.find('iframe');
        const player = new Vimeo.Player($iframe);

        if ($trailerbutton.length) {
          $trailerbutton.on('click', function(event){
            event.preventDefault();
            if ($iframe.length ) {
              player.play();
              $trailerbutton.addClass('hidden');
            }
          });
        }


        $trailer.find('.overlay-close').on('click', function(event){
          event.preventDefault();
          if ($iframe.length ) {
            player.pause();
          }
          $trailer.removeClass('show');
          if( displayDays > 0 ) {
            Cookies.set('displaytrailer', 'hidden', { expires: displayDays });
          }
        });
      }
    }
  };

  var initSlider = function (withFilter) {
    $slider = $('.carousel');
    if ($slider.length) {
      $slider.slick({
        lazyLoad: 'progressive',
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 7000,
        infinite: true,
        // pauseOnHover: true,
        // pauseOnFocus: false,
        speed: 300,
        // fade: true,
      });
      $slider.on('lazyLoaded', function (e, slick, image, imageSource) {
        image.attr('src',''); //remove source
        image.css('visibility','hidden'); //remove source
        image.parent().css('background-image','url("'+imageSource+'")'); //replace with background instead
        image.parent().css('background-size','cover');
        image.parent().css('background-position','center center');
        image.parent().css('background-repeat','no-repeat');
      });
    }
  };

  var initGrid = function () {
    // JavaScript to be fired on all pages
    $container = $('.masonry,.gallery');
    if ($container.length) {
      $container.each(function() {
        $elem = $(this)[0];
        var msnry = new Masonry( $elem, {
          itemSelector: '.widget,.gallery-item',
          columnWidth: '.widget-spacer,.gallery-item',
          percentPosition: true
        });
        imagesLoaded( $elem, function(){
          msnry.layout();
        });
      });
    }
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        initSlider();
        initBanner();
        initGrid();
        initPhotoSwipeFromDOM('.container');

        $('.grid .item-outer').matchHeight();


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.content-col').matchHeight();
        initBanner();
        initTrailerPopup();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
